import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { Answer } from '../../models/answers';

export interface ResponsesPageProps {
  name: string;
  pageName: string;
  answers: Answer[];
}

interface ResponsesPageCardsProps {
  answers: Answer[];
}

const ResponsesPageCards = ({ answers }: ResponsesPageCardsProps) => {
  const direction = localStorage.getItem('contentDirection');

  return (
    <div
      className={`row justify-content-center ${
        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
      }`}
    >
      {answers.map((item) => {
        return (
          <div className="col-md-12">
            <div className="report-driverCard">
              <div className="mb-3 d-flex">
                <span className="report-driverCard-title m-0">
                  Q: {item.question}
                </span>
              </div>
              <p>A: {item.answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ResponsesPageBlock = ({
  name,
  pageName,
  answers,
}: ResponsesPageProps) => {
  const { t } = useTranslation();

  return (
    <section className="assessment-sec career-driver-block page-break">
      <h2>{t('Responses page title', { pageName })}</h2>
      <p>
        {t('Responses page description', { name: name, pageName: pageName })}
      </p>
      <div className="thumb-block">
        <ResponsesPageCards answers={answers} />
      </div>
    </section>
  );
};

export default ResponsesPageBlock;
