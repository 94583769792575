import React from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '../../models/applications';
import CircularProgress from './CircularProgressbarCandidateReport';

interface IData {
  score: number;
  name: string;
  isAssessed: boolean;
}

interface AdvanceHeadIntroProps {
  name: string;
  data: IData[];
  fit_scoreData: number;
  first_name: string;
  job: Job;
}

const AdvancedHeadIntro = (props: AdvanceHeadIntroProps) => {
  const { t, i18n } = useTranslation();

  let overallPercentage = props.fit_scoreData;
  let scoreText = '';
  let scoreColor = '';

  if (overallPercentage <= 39) {
    scoreText = t('Low');
    scoreColor = '#ff0000';
  } else if (40 <= overallPercentage && overallPercentage <= 69) {
    scoreText = t('Medium');
    scoreColor = '#ffc905';
  } else {
    scoreText = t(`High`);
    scoreColor = '#27A4A1';
  }

  return (
    <section className="assessment-sec">
      <div className="candidate-report">
        <h1>{props.name}</h1>
        <div className="d-flex align-items-center">
          <div>
            <CircularProgress
              width={100}
              height={100}
              percentage={overallPercentage}
              texttoshow={`${
                overallPercentage >= 0
                  ? overallPercentage
                  : i18n.language === 'ar'
                  ? 'ــ'
                  : 'N/A'
              }`}
              progressColor={overallPercentage >= 0 ? scoreColor : '#d5d6d5'}
            />
            <div className="text-center mt-1">
              <strong>{t('Fit Score')}</strong>
            </div>
          </div>
          <div className="mx-4">
            <h3 className="text-secondary">{t('Fit Score')}</h3>
            <p className="text-sm ">
              <span className="text-uppercase" style={{ color: scoreColor }}>
                {scoreText}
              </span>
              &nbsp;
              <span
                dangerouslySetInnerHTML={{
                  __html: t('match to role', {
                    job: props.job.title,
                  }),
                }}
              />
            </p>
          </div>
        </div>
        <div className="mt-4">
          <div className="d-flex flex-column flex-md-row">
            {props.data.map((item: IData, index: number) => {
              let color;
              if (item.score <= 39) {
                color = '#ff0000';
              } else if (40 <= item.score && item.score <= 69) {
                color = '#ffc905';
              } else {
                color = '#27A4A1';
              }
              return (
                <div className="flex-grow-1 flex-shrink-1 w-md-0">
                  <div className="d-flex">
                    <div className="d-flex flex-row align-items-center flex-md-column mt-3 mt-md-0">
                      <CircularProgress
                        width={75}
                        height={75}
                        percentage={item.isAssessed ? item.score : -1}
                        texttoshow={item.isAssessed ? `${item.score}` : '-1'}
                        progressColor={color}
                        textColor={item.isAssessed ? color : '#d5d6d5'}
                      />
                      <div className="text-center ml-3 ml-md-0 mt-md-1">
                        <strong>
                          {t(item.name)
                            .split(' ')
                            .map((str, index) => {
                              return (
                                <React.Fragment>
                                  {str}
                                  {index === 1 ? ' ' : <br />}
                                </React.Fragment>
                              );
                            })}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvancedHeadIntro;
