import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizedDate } from '../../utils/utils';

export interface PrintFooterProps {
  name: string | null;
}

const PrintFooter: React.FC<PrintFooterProps> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="print-footer mt-4 d-none justify-content-between position-fixed print-footer">
      {t('Report generated by WhiteBox HR with date', {
        name: props.name,
        date: localizedDate(new Date(), i18n.language),
      })}
    </footer>
  );
};

export default PrintFooter;
