import React from 'react';
import { Application } from '../../models/applications';
import { useTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { mainColor } from '../../utils/theme';
import { localizedDate } from '../../utils/utils';

const Highlight: React.FC = (props) => {
  const direction = localStorage.getItem('contentDirection');

  return (
    <span
      className={direction === CONTENT_DIRECTION.RTL ? 'ml-5' : 'mr-5'}
      style={{ color: mainColor }}
    >
      {props.children}
    </span>
  );
};

export interface CoverProps {
  name: string | null;
  applied: Application['applied'] | null;
  title: string;
  showConfidentialityNote: boolean;
}

const Cover: React.FC<CoverProps> = (props) => {
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem('contentDirection');
  return (
    <section className="print-cover flex-column">
      <div>
        <h1
          className={`font-weight-semi-bold report-xlFont ${
            direction === CONTENT_DIRECTION.RTL && 'text-right'
          }`}
        >
          {t(props.title)}
        </h1>
        <div className="mt-5 pt-5">
          <p className="d-flex report-lgFont">
            <Highlight>{t('Candidate Name')}</Highlight> {props.name}
          </p>
          <p className="d-flex mt-4 report-lgFont">
            <Highlight>{t('Assessment Date')}</Highlight>{' '}
            {props.applied &&
              localizedDate(new Date(props.applied), i18n.language)}
          </p>
        </div>
        {props.showConfidentialityNote && (
          <p className="text-justify mt-5 pt-5 report-mdFont">
            {t(
              'The information provided in this report is confidential and must be treated with care and in accordance with applicable data protection laws Based on the type of assessment tools utilized to produce this report the validity of its content is 18-24 months',
            )}
          </p>
        )}
      </div>
      <div className="mt-5 report-smFont">
        {t('All rights reserved', { year: new Date().getFullYear() })}
      </div>
    </section>
  );
};

export default Cover;
