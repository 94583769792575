import React, { useState } from 'react';
import { ReactComponent as ArrowUp } from '../../assets/img/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/img/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../hooks/useIsTablet';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { TraitScore } from '../../models/applications';

export interface CandidateReportProps {
  data: TraitScore[];
  name?: string;
  isLineManager?: boolean;
  companyData?: any;
}

const StrengthBlock = ({
  data,
  name = '',
  isLineManager = false,
  companyData = {},
}: CandidateReportProps) => {
  const [isStrengthVisible, setIsStrengthVisible] = useState(false);
  const [isPerfomingVisible, setIsPerfomingVisible] = useState(false);
  const dynamicPage =
    companyData?.custom_report?.competencies_static_report_page;
  const dynamicPageHighlights =
    companyData?.custom_report?.dynamically_highlight_competency_boxes;
  const { t, i18n } = useTranslation();
  const isTablet = useIsTablet();
  const traits = data.map((item) => {
    let trait = {
      id: item.trait.id,
      group_id: item.trait.group_id,
      icon: item.trait.icon,
      name: '',
      display_name: '',
      title: '',
      short_description: '',
      company_definition: '',
      long_description: '',
      at_their_best: item.trait.at_their_best,
      at_their_moderate: item.trait.at_their_moderate,
      at_their_worst: item.trait.at_their_worst,
      use_company_definition: false,
      sten_score_percentage: 0,
    };
    if (i18n.language === 'ar') {
      trait.name = item.trait.name_ar ? item.trait.name_ar : item.trait.name;
      trait.at_their_best = item.trait.at_their_best_ar
        ? item.trait.at_their_best_ar
        : item.trait.at_their_best;
      trait.at_their_moderate = item.trait.at_their_moderate_ar
        ? item.trait.at_their_moderate_ar
        : item.trait.at_their_moderate;
      trait.at_their_worst = item.trait.at_their_worst_ar
        ? item.trait.at_their_worst_ar
        : item.trait.at_their_worst;
      trait.display_name = item.trait.display_name_ar
        ? item.trait.display_name_ar
        : item.trait.name;
      trait.title = item.trait.title_ar
        ? item.trait.title_ar
        : item.trait.title;
      trait.short_description = item.trait.short_description_ar
        ? item.trait.short_description_ar
        : item.trait.short_description;
      trait.company_definition = item.trait.company_definition_ar
        ? item.trait.company_definition_ar
        : item.trait.company_definition;
      trait.long_description = item.trait.long_description_spp_ar
        ? item.trait.long_description_spp_ar
        : item.trait.long_description_spp;
      if (isLineManager === true) {
        trait.long_description = item.trait.long_description_ar
          ? item.trait.long_description_ar
          : item.trait.long_description;
      }
    } else {
      trait.name = item.trait.name;
      trait.display_name = item.trait.display_name;
      trait.title = item.trait.title;
      trait.short_description = item.trait.short_description;
      trait.company_definition = item.trait.company_definition;
      trait.long_description = item.trait.long_description_spp;
      if (isLineManager === true) {
        trait.long_description = item.trait.long_description;
      }
    }
    trait.use_company_definition = item.trait.use_company_definition;
    trait.sten_score_percentage = item.sten_score_percentage;
    return trait;
  });
  const customTraits = traits.filter((item) => item.group_id === null);

  const direction = localStorage.getItem('contentDirection');
  const isAr = i18n.language === 'ar' && direction === CONTENT_DIRECTION.RTL;

  return (
    <section
      style={dynamicPage ? { marginTop: -70 } : {}}
      className="strength-sec candidate-report report text-align-start"
    >
      {dynamicPage && (
        <h6
          style={{
            textAlign: 'center',
            fontWeight: 600,
            backgroundColor: 'rgb(37, 164, 161)',
            color: '#fff',
            padding: 5,
            borderTop: '1px solid #000',
            borderBottom: '1px solid #000',
            margin: 0,
          }}
        >
          {t('Behaviors that could be associated with the below noted scores')}
        </h6>
      )}
      {name && !isLineManager && (
        <h1>
          {t('Name Stand Out Strengths', {
            name,
          })}
        </h1>
      )}

      {name && isLineManager && <h1>{name}</h1>}

      <div className="strength-sec-container">
        <div
          className={
            dynamicPage ? 'strength-sec-column-nowidth' : 'strength-sec-column'
          }
          style={dynamicPage ? { width: 200 } : {}}
        >
          <div
            className="strength-sec-title-wrapper"
            style={dynamicPage ? { display: 'none' } : {}}
            onClick={() => {
              setIsStrengthVisible(!isStrengthVisible);
            }}
          >
            <h3>{t('Significant identified strengths')}</h3>
            {isTablet && isStrengthVisible && <ArrowUp />}
            {isTablet && !isStrengthVisible && <ArrowDown />}
          </div>
          {(customTraits.length === 0 ? traits : customTraits)
            .slice(0, dynamicPage ? 10 : 5)
            .map((item, itemIndex) => (
              <div
                key={item.id}
                className={`identity-list-wrapper strength-sec-card ${
                  (isStrengthVisible && isTablet) || !isTablet ? '' : 'd-none'
                } ${
                  customTraits.length !== 0
                    ? 'identity-list-wrapper-custom'
                    : ''
                }
                ${dynamicPage ? 'dynamic-page' : ''}
                ${isAr ? 'ar' : ''}
                `}
                style={
                  dynamicPage
                    ? {
                        padding: '5px',
                        backgroundColor:
                          itemIndex % 2 !== 0 ? 'rgba(37, 164, 161, 0.1)' : '',
                      }
                    : {}
                }
              >
                {dynamicPage ? (
                  <>
                    <div
                      className="identity-block-content"
                      style={{ width: 'unset' }}
                    >
                      <h6
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {item.display_name}
                      </h6>
                      <div
                        className={`identity-svg ${
                          direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                        }`}
                        style={{
                          width: 'unset',
                          height: 40,
                          margin: 0,
                        }}
                      >
                        <img
                          src={item.icon}
                          style={{ maxHeight: '100%' }}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`identity-svg ${
                        direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                      }`}
                    >
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="identity-block-content">
                      <h4>{item.display_name}</h4>
                      <span>{item.short_description}</span>
                    </div>
                  </>
                )}
              </div>
            ))}
          <div style={dynamicPage ? { height: 28 } : { display: 'none' }}></div>
        </div>
        {dynamicPage ? (
          <>
            <div className="strength-sec-column-nowidth">
              {(customTraits.length === 0 ? traits : customTraits)
                .slice(0, 10)
                .map((item, itemIndex) => (
                  <div
                    key={item.id}
                    style={
                      itemIndex % 2 !== 0
                        ? { backgroundColor: 'rgba(37, 164, 161, 0.1)' }
                        : {}
                    }
                    className={`strength-sec-card dynamic-page ${
                      (isPerfomingVisible && isTablet) || !isTablet
                        ? ''
                        : 'd-none'
                    }
                    ${isAr ? 'ar' : ''}
                    `}
                  >
                    <div
                      className={`performing-best-list-wrapper ${
                        item.sten_score_percentage >= 70 &&
                        dynamicPageHighlights
                          ? 'chosen-box'
                          : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item.at_their_best,
                      }}
                    ></div>
                  </div>
                ))}
              <div
                className="strength-sec-title-wrapper"
                style={{
                  width: '100%',
                  height: 28,
                  padding: '0px 15px',
                  border: 'none',
                }}
                onClick={() => {
                  setIsPerfomingVisible(!isPerfomingVisible);
                }}
              >
                <h6
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {t('High [ 70% + ]')}
                </h6>
                {isTablet && isPerfomingVisible && <ArrowUp />}
                {isTablet && !isPerfomingVisible && <ArrowDown />}
              </div>
            </div>
            <div className="strength-sec-column-nowidth">
              {(customTraits.length === 0 ? traits : customTraits)
                .slice(0, 10)
                .map((item, itemIndex) => (
                  <div
                    key={item.id}
                    style={
                      itemIndex % 2 !== 0
                        ? { backgroundColor: 'rgba(37, 164, 161, 0.1)' }
                        : {}
                    }
                    className={`strength-sec-card dynamic-page ${
                      (isPerfomingVisible && isTablet) || !isTablet
                        ? ''
                        : 'd-none'
                    }
                    ${isAr ? 'ar' : ''}`}
                  >
                    <div
                      className={`performing-best-list-wrapper ${
                        item.sten_score_percentage >= 40 &&
                        item.sten_score_percentage < 70 &&
                        dynamicPageHighlights
                          ? 'chosen-box'
                          : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item.at_their_moderate,
                      }}
                    ></div>
                  </div>
                ))}
              <div
                className="strength-sec-title-wrapper"
                style={{
                  width: '100%',
                  height: 28,
                  padding: '0px 15px',
                  border: 'none',
                }}
                onClick={() => {
                  setIsPerfomingVisible(!isPerfomingVisible);
                }}
              >
                <h6
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {t('Moderate [ 40% - 69% ]')}
                </h6>
                {isTablet && isPerfomingVisible && <ArrowUp />}
                {isTablet && !isPerfomingVisible && <ArrowDown />}
              </div>
            </div>
            <div className="strength-sec-column-nowidth">
              {(customTraits.length === 0 ? traits : customTraits)
                .slice(0, 10)
                .map((item, itemIndex) => (
                  <div
                    key={item.id}
                    style={
                      itemIndex % 2 !== 0
                        ? { backgroundColor: 'rgba(37, 164, 161, 0.1)' }
                        : {}
                    }
                    className={`strength-sec-card dynamic-page ${
                      (isPerfomingVisible && isTablet) || !isTablet
                        ? ''
                        : 'd-none'
                    }
                    ${isAr ? 'ar' : ''}
                    `}
                  >
                    <div
                      className={`performing-best-list-wrapper ${
                        item.sten_score_percentage >= 0 &&
                        item.sten_score_percentage < 40 &&
                        dynamicPageHighlights
                          ? 'chosen-box'
                          : ''
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item.at_their_worst,
                      }}
                    ></div>
                  </div>
                ))}
              <div
                className="strength-sec-title-wrapper"
                style={{
                  width: '100%',
                  height: 28,
                  padding: '0px 15px',
                  border: 'none',
                }}
                onClick={() => {
                  setIsPerfomingVisible(!isPerfomingVisible);
                }}
              >
                <h6
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  {t('Low [ 0% - 39% ]')}
                </h6>
                {isTablet && isPerfomingVisible && <ArrowUp />}
                {isTablet && !isPerfomingVisible && <ArrowDown />}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="strength-sec-column">
              <div
                className="strength-sec-title-wrapper"
                onClick={() => {
                  setIsPerfomingVisible(!isPerfomingVisible);
                }}
              >
                <h3>
                  {isLineManager
                    ? t('When performing at their best they')
                    : t('When performing at your best you')}
                </h3>
                {isTablet && isPerfomingVisible && <ArrowUp />}
                {isTablet && !isPerfomingVisible && <ArrowDown />}
              </div>

              {(customTraits.length === 0 ? traits : customTraits)
                .slice(0, 5)
                .map((item) => (
                  <div
                    key={item.id}
                    className={`strength-sec-card ${
                      (isPerfomingVisible && isTablet) || !isTablet
                        ? ''
                        : 'd-none'
                    }`}
                  >
                    <div
                      className="performing-best-list-wrapper"
                      dangerouslySetInnerHTML={{
                        __html: item.long_description,
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default StrengthBlock;
