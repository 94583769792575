import axios from 'axios';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { fetchUserData } from '../../actions/userPersonalInfo';
import Loader from '../../components/Loader/Loader';
import CircularProgress from '../../components/Report/CircularProgressbarCandidateReport';
import { BASE_URL, PATH } from '../../constants/variables';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import StrengthBlock from '../../components/Report/StrengthBlock';
import { ResponsesPageBlock } from '../../components/Report';
import Cover from '../../components/Report/Cover';
import Header from '../../components/Header/Header';
import StaticFooter from '../../components/StaticFooter/StaticFooter';
import PrintFooter from '../../components/Report/PrintFooter';
import setToken from '../../setToken';

class PersonalityTestReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      traitScores: [],
      customTraits: [],
      userInfo: {},
      loading: true,
      saving: false,
      appliedDate: null,
      customReport: false,
      profileAnswers: [],
      technicalAnswers: [],
      surveyAnswers: [],
    };

    this.pdfRootRef = React.createRef();
  }

  componentDidMount() {
    let token = localStorage.getItem('token');
    if (!token) {
      ({ token } = queryString.parse(this.props.location.search));
    }
    if (token) {
      setToken(token);
    }

    axios
      .get(
        `${BASE_URL}${PATH}applications/${this.props.match.params.id}/report`,
      )
      .then((res) => {
        if (res.status === 200) {
          const candidateId = res.data.candidate_id;
          const applied = res.data.applied;
          const traitScores = res.data.trait_scores;
          const customTraits = traitScores.filter(
            (item) => item.trait.group_id === null,
          );
          const customReport = res.data.job.company.custom_report;
          const profileAnswers = res.data.profile_answers;
          const technicalAnswers = res.data.technical_answers;
          const surveyAnswers = res.data.survey_answers;
          axios
            .all([axios.get(`${BASE_URL}/api/auth/candidates/${candidateId}`)])
            .then((res) => {
              this.setState({
                traitScores: traitScores,
                customTraits: customTraits,
                userInfo: res[0].data,
                loading: false,
                appliedDate: applied,
                customReport: customReport,
                profileAnswers: profileAnswers,
                technicalAnswers: technicalAnswers,
                surveyAnswers: surveyAnswers,
              });
            });
        }
      });
  }

  render() {
    if (this.state.loading || !this.state.userInfo) {
      return <Loader />;
    }

    const {
      traitScores,
      customTraits,
      customReport,
      profileAnswers,
      technicalAnswers,
      surveyAnswers,
    } = this.state;
    const { t, i18n } = this.props;
    const name = this.state.userInfo.first_name;
    const fullName = this.state.userInfo.full_name;
    const direction = localStorage.getItem('contentDirection');

    return (
      <div
        id="pdf-root"
        className={`container report text-align-start ${
          direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
        }`}
      >
        <Header
          // @ts-ignore
          companylogo={'/assets/img/logo.png'}
          isDashboardHeader
        />
        <Cover
          name={fullName}
          applied={this.state.appliedDate}
          title={t('Candidate Report')}
          showConfidentialityNote={false}
        />
        <div
          className={`candidate-report report page-break text-align-start mx-auto 
            ${direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''}
          `}
          ref={this.pdfRootRef}
          style={
            {
              // TODO test if pdf button are active
              // maxWidth: this.state.saving ? 1024 : 1260,
            }
          }
        >
          <div className="candidate-report-content-title-wrapper">
            <h1 className="content-title">
              {t('Your Dominant Competencies and Traits')}
            </h1>
          </div>

          <p className="mb-5 candidate-report-paragraph">
            {t(
              'Thank you for taking the assessment your stand out strengths are the following',
              {
                name,
              },
            )}
          </p>
          <div className="intro-candidate-circular-block">
            {(customTraits.length === 0 ? traitScores : customTraits)
              .slice(0, 3)
              .map((item, index) => (
                <div className="circular-block-card">
                  <CircularProgress
                    key={item.trait.id}
                    percentage={item.sten_score_percentage}
                    texttoshow={index + 1}
                  />
                  <span>
                    {i18n.language === 'ar' && item.trait.name_ar
                      ? item.trait.name_ar
                      : item.trait.name}
                  </span>
                </div>
              ))}
          </div>
          <p className="mb-5 candidate-report-paragraph">
            {t(
              'Other significant identified strengths are listed below We encourage you to focus on optimizing these strengths to achieve career success',
            )}
          </p>

          <StrengthBlock data={traitScores} />

          {customReport &&
          customReport.portal_manager_report &&
          customReport.show_profile_answers_portal &&
          profileAnswers.length ? (
            <ResponsesPageBlock
              name={this.state.me ? this.state.me.first_name : ''}
              pageName={t('Profile')}
              answers={this.state.profileAnswers}
            />
          ) : (
            <></>
          )}

          {customReport &&
          customReport.portal_manager_report &&
          customReport.show_technical_answers_portal &&
          technicalAnswers.length ? (
            <ResponsesPageBlock
              name={this.state.me ? this.state.me.first_name : ''}
              pageName={t('Technical Assessment')}
              answers={this.state.technicalAnswers}
            />
          ) : (
            <></>
          )}

          {customReport &&
          customReport.portal_manager_report &&
          customReport.show_survey_answers_portal &&
          surveyAnswers.length ? (
            <ResponsesPageBlock
              name={this.state.me ? this.state.me.first_name : ''}
              pageName={t('Pulse Survey')}
              answers={this.state.surveyAnswers}
            />
          ) : (
            <></>
          )}

          <div className="print-button candidate-report-actions-wrapper">
            <button
              style={{ marginLeft: '20px' }}
              className="test-result-block-button theme-bg"
              type="button"
              onClick={() => {
                window.print();
              }}
            >
              <i className="fas fa-download"></i> {t('Download')}
            </button>
          </div>
        </div>
        <StaticFooter />
        <PrintFooter name={fullName} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchUserData,
  })(PersonalityTestReport),
);
